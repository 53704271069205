let DataDict = {
	"link_type": [{
		"id": "1138",
		"label": "无操作",
		"value": "0"
	}, {
		"id": "1139",
		"label": "连接地址",
		"value": "1"
	}],
	"open_method": [{
		"id": "1136",
		"label": "当前窗口",
		"value": "0"
	}, {
		"id": "1137",
		"label": "新窗口/新标签",
		"value": "1"
	}],
	"org_type": [{
		"id": "107",
		"label": "科研机构",
		"value": "0"
	}, {
		"id": "108",
		"label": "检测机构",
		"value": "1"
	}, {
		"id": "109",
		"label": "风险评估实验室",
		"value": "2"
	}, {
		"id": "110",
		"label": "风险评估实验站",
		"value": "3"
	}, {
		"id": "112",
		"label": "高校",
		"value": "4"
	}, {
		"id": "113",
		"label": "企业",
		"value": "5"
	}, {
		"id": "114",
		"label": "绿色食品定点检测机构",
		"value": "6"
	}, {
		"id": "115",
		"label": "农产品地理标志定点检测机构",
		"value": "7"
	}, {
		"id": "116",
		"label": "名特优新定点检测机构",
		"value": "8"
	}, {
		"id": "117",
		"label": "其他",
		"value": "9"
	}, {
		"id": "1171",
		"label": "农业转基因检测机构",
		"value": "10"
	}, {
		"id": "1172",
		"label": "国家级农产品检测机构",
		"value": "11"
	}, {
		"id": "1173",
		"label": "农业农村部质量检测机构",
		"value": "12"
	}],
	"column_type": [{
		"id": "1129",
		"label": "首页",
		"value": "0"
	}, {
		"id": "1130",
		"label": "检测服务",
		"value": "1"
	}, {
		"id": "1131",
		"label": "品质品价服务",
		"value": "2"
	}, {
		"id": "1132",
		"label": "质量合格证服务",
		"value": "3"
	}, {
		"id": "1133",
		"label": "溯源查询中心",
		"value": "4"
	}, {
		"id": "1134",
		"label": " 新闻动态",
		"value": "5"
	}, {
		"id": "1135",
		"label": "关于我们",
		"value": "6"
	}],
	"org_cert_type": [{
		"id": "123",
		"label": "CMA",
		"value": "0"
	}, {
		"id": "124",
		"label": "CATL",
		"value": "1"
	}, {
		"id": "125",
		"label": "CNAS",
		"value": "2"
	}, {
		"id": "126",
		"label": "其他",
		"value": "3"
	}],
	"show_home_page": [{
		"id": "1125",
		"label": "否",
		"value": "0"
	}, {
		"id": "1126",
		"label": "是",
		"value": "1"
	}],
	"status": [{
		"id": "1127",
		"label": "禁用",
		"value": "0"
	}, {
		"id": "1128",
		"label": "启用",
		"value": "1"
	}]
}
export default DataDict
// module.exports = DataDict