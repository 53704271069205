let Local = {
    'page': [
        {
            value: '0',
            label: '微页面',
            children: [{
                value: '0',
                label: '微页面'
            }]
        },
        {
            value: '1',
            label: '商品',
            children: [{
                value: '0',
                label: '全部商品'
            }, {
                value: '1',
                label: '店铺类目'
            }]
        },
        {
            value: '2',
            label: '系统功能',
            children: [{
                value: '0',
                label: '在线客服'
            }, {
                value: '1',
                label: '购物车'
            }, {
                value: '2',
                label: '店铺详情'
            }]
        }
    ]
}
module.exports = Local