import {createStore} from "vuex"

//创建store实例
const store=createStore({
    state(){
        return {
            "windowWidth":0,
            "theme":'',
            "module":"",
            "portalInfo":{},
            "color":"",
            "font":""
        }
    },
    mutations:{
      widthChange(state,width) {
        // 变更状态
        state.windowWidth = width
      },
      setTheme(state,theme) {
        // 变更状态
        console.log(theme)
        state.theme = theme
      },
      setModule(state,module) {
        // 变更状态
        state.module = module
      },
      setColor(state,color) {
        // 变更状态
        state.color = color
      },
      setFont(state,font) {
        // 变更状态
        state.font = font
      },
      setPortalInfo(state,data) {
        // 变更状态
        state.portalInfo = data;
      },
    }
})
export default store;