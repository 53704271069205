let Api = {
  "N_SHOPHOME_FINDESPRODUCTPAGELIST": "/trade/n/ShopHome/findEsProductPageList", //商品列表
	"SYSTEM_N_FINDCOMMMAGETREE": "/system/n/findCommMageTree", //查询基础品类树
	"TRADE_SHOPHOME_FINDDEFAULTINFO": "/trade/n/ShopHome/findDefaultInfo", //获取店铺列表
	"TRADE_SHOPHOME_FINDESPRODUCTINFO": "/trade/n/ShopHome/findEsProductInfo", //查询商品索引详情
	"TRADE_SHOPHOME_FINDSHOPINFO": "/trade/n/ShopHome/findShopInfo", //查询店铺信息
	"TRADE_SHOPHOME_COMMSALECATG_LIST": "/trade/n/ShopHome/commSaleCatg/list", //查询店铺信息
  
	"INDUSTRYMANGE_FINDDEFAULT": "/portal-api/n/IndustryMange/findDefault", //查询默认产业基本信息
	"TRADE_N_GETUNLIMITED": "/trade/n/getUnlimited", //获取二维码

  
}
module.exports = Api