import { createApp } from 'vue'

//引入elementUI-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// import VueAMap from 'vue-amap'
import App from './App.vue'


//引入vue-router
import router from "./router";
//引入vuex
import store from "./store"
//引入静态资源
import "../public/js/api.js"
import '../public/js/area.js'
//引入静态资源
import "./assets/css/global.less"
//全局属性，方法，组件入口
import plugins from "./assets/js/plugins"


import NProgress from 'nprogress'  // 进度条；进度指示器
import 'nprogress/nprogress.css' // 进度条；进度指示器 样式
import zhCn from 'element-plus/lib/locale/lang/zh-cn'//element-plus

// 引入主题色

import '@/assets/css/theme.scss'
import "@/assets/font/font.css";
import * as echarts from 'echarts'
 
console.log(process.argv.project)

//配置插件
 
NProgress.configure({     
    easing: 'ease',  // 动画方式    
    speed: 500,  // 递增进度条的速度    
    showSpinner: false, // 是否显示加载ico    
    trickleSpeed: 200, // 自动递增间隔    
    minimum: 0.3 // 初始化时的最小百分比
})
 
 
//监听路由跳转，进入页面执行插件动画。
router.beforeEach((to, from , next) => {
    // 每次切换页面时，调用进度条
    NProgress.start();
 
    // 这个一定要加，没有next()页面不会跳转的。这部分还不清楚的去翻一下官网就明白了
    next();
});
 
 
//跳转结束了
router.afterEach(() => {  
    // 在即将进入新的页面组件前，关闭掉进度条
    NProgress.done()
})

  // 创建 SDK 实例，TIM.create() 方法对于同一个 SDKAppID 只会返回同一份实例
const app = createApp(App)
app.use(ElementPlus,{'locale':zhCn})
app.use(router)
// app.use(VueAMap)
app.use(store)
app.use(plugins)
app.config.globalProperties.$echarts=echarts;
window.vm=app.mount('#app')
