import Axios from 'axios'
import { getCookie, setCookie } from './cookie'
import Api from './api.js'
import { setTimeout } from 'core-js';
import { ElMessage } from 'element-plus'
import router from '@/router';
var errFlag = true;//解决循环弹出错误提示标识
var noUseFlag = true;//解决循环弹出"服务不可以，联系管理员"标识

let pendingRequestArr = [];//保存pending状态的网络请求 
export function request(config) {
	let cancelToken = Axios.CancelToken;

	let authorization = null;
	if (getCookie("userInfo")) {
		if (JSON.parse(getCookie("userInfo"))) {
			authorization = JSON.parse(getCookie("userInfo")).tokenType + " " + JSON.parse(getCookie("userInfo")).accessToken
		}
	}
	// authorization="bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJwbGF0X2FkbWluIiwidXNlcl9uYW1lIjoicGxhdF9hZG1pbiIsIm9wZW5JZCI6IjA2N2VmYTllNjg0ZDRkZTg4ZTU0NGU2ZWQ3ODY2Mzk5IiwidXNlcklkIjo3MTUzOTg4NjQyODkyNTA0NjAxLCJhdXRob3JpdGllcyI6WyJST0xFX2FjY291bnQiLCJST0xFX3VzZXIiLCJ1c2VyOnB3ZHVwZCIsIlJPTEVfcGxhdCJdLCJleHAiOjE3MTQzOTkzNzR9.lX_WXx6w5mdZhbH4rVEZalBcB_ri2oc5DihAVkNBbe-sQdH84Va2pmjsqp258eQgBPXKPV_QaBpqn3yqIPzdCjGQ_JNyQAsaEM3ZK7CqCdFt0VVgdpffKtx0nlziLvHjlAJPRP0aLmQcB14h8DHP3frAv7yole35PnkuD9DWc9VOlAmPn-C3wW0aYXg2ya8kBjedTWEBPg6PEATf0Rafgk9K3wbrMlqVmS_0wZ0uOKMSyKCoK-E7_SxEtjuq5Dx-jYsg42SrPrbsE78JL00NCg0cMdOmR8Qq-8mLn581jf9D3zjXy4p3zO97WR_sZzQgh3V16HAU1a-a3UfupJNgdg"
	setCookie('authorization',authorization)
	//取消请求fn
	let cancelPendingFn = (res) => {
		console.log(res)
		// pendingRequestArr.forEach((item,index)=>{
		// 	if(item.url==res.url){
		// 		item.cancelFn();
		// 		pendingRequestArr.splice(index,1);//从数组移除当前请求记录
		// 	}
		// })
	}

	//创建Axios实例，添加通用配置
	const instance = Axios.create({
		method: config.method ? config.method : "post",
		timeout: config.timeout ? config.timeout : 30000,
		headers: config.header ? config.header : {
			// 'content-type': 'application/json',
			'Authorization': authorization,
			'withCredentials': true
		},
	})


	//实例请求拦截器
	instance.interceptors.request.use((res) => {
		return new Promise(resolve=>{
			setTimeout(()=>{
				cancelPendingFn(res);
				console.log(Api,res.url)
				if(!res.url){return}
				if(res.url && res.url.substr(0,4).toLowerCase() == "http" ){
					res.url;
				}else{
					res.url = process.env.VUE_APP_BASE_API+process.env.VUE_APP_PUBLIC_NAME+Api[res.url]
				}
				res.cancelToken = new cancelToken((cancelFn) => {
					pendingRequestArr.push({ "url": res.url, "cancelFn": cancelFn })
				})
				resolve(res)
			},300)
		})
	}, (err) => {
		return Promise.reject(err);
	})

	//实例响应拦截器
	instance.interceptors.response.use((res) => {
		if(res.data.code && res.data.code!='000'){
			if (noUseFlag && !res.config.cancelMsg) {
				noUseFlag = false;
				ElMessage.error({
					message: res.data.msg,
					duration: '2500',
					onClose: function () {
						noUseFlag = true;
					}
				});
			}
		}
		cancelPendingFn(res.config);
		return res;
	}, (error) => {

		let err = error.response;
		if (errFlag) {
			if(err){
				if (err.status == "401") {
					setCookie('userInfo', '');
					setCookie('loginName', '');
					router.push({
						path:'login'
					})
				} else if (err.status == "500") {
					// errFlag = false;
					ElMessage({
						message: err.data.msg,
						onClose: function () {
							errFlag = true;
						}
					});
				}
			}else{
				ElMessage({
					message: err.message,
					onClose: function () {
						errFlag = true;
					}
				});
			}
			
		}



		// var isErrInfo = err.response.data.error_description.indexOf('Access token expired');//权限是否过期
		//    var isErrInfo1 = err.response.data.error_description.indexOf('An Authentication object was not found in the SecurityContext');//没有token串
		//       if(errFlag) {
		//         if( isErrInfo>=0 ) {
		//           setCookie('userInfo','');
		//           setCookie('loginName','');
		//           localStorage.setItem("menuBuyType","");
		//           errFlag = false;
		//           vm.$router.push('/'); 
		//         } else if( isErrInfo1>=0 ) {
		//           errFlag = false;
		//           vm.$router.push('/'); 
		//         } else {
		//           ElMessage('服务暂时不可用，请联系管理员');
		//         }
		//         return false;
		//       }
		//return Promise.reject(err);
		return Promise.reject(err).catch((error) => {
			if (error.data == "server exception: code = 404 msg = Not Found") {//说明没开通此服务
				if (noUseFlag) {
					noUseFlag = false;
					ElMessage.error({
						message: '服务暂时不可用，请联系管理员',
						duration: '4000',
						onClose: function () {
							noUseFlag = true;
						}
					});
				}
			}
			if (error.status == '500') {
				if (noUseFlag) {
					noUseFlag = false;
					ElMessage.error({
						message: error.data.msg ? error.data.msg : '服务端异常',
						duration: '3000',
						onClose: function () {
							noUseFlag = true;
						}
					});
				}
			}
			if (error.status == '403') {
				if (noUseFlag) {
					noUseFlag = false;
					ElMessage.error({
						message: error.data.msg ? error.data.msg : '服务端异常',
						duration: '3000',
						onClose: function () {
							noUseFlag = true;
						}
					});
				}
			}
			return Promise.reject(err);//为了在具体页面请求中 catch中获取错误信息
		});
		
	})

	//返回axios的实例，也是一个promise
	return instance(config)
	
}

/*
具体页面中使用方式
request({
	url:"xxxx",
	method:"post",
	data:{},
}).then((res)=>{

}).catch((err)=>{

})

*/
