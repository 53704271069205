function getCookie(name){
    var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    arr = document.cookie.match(reg)
        if (arr)
            return unescape(arr[2]);
        else
            return null;
        
}

//设置cookie,增加到vue实例方便全局调用
function setCookie(c_name, value, expiredays) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + expiredays);
    document.cookie = c_name + "=" + escape(value) + ((expiredays == null) ? ";path=/" : ";expires=" + exdate.toUTCString()+";path=/");
}

//删除cookie
function delCookie(name) {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = getCookie(name);
    if (cval != null){
        document.cookie = name + "=" + cval + ";expires=" + exp.toUTCString()+";path=/";
    }
        
}
//检查cookies
function checkCookie(c_name) {
    if (document.cookie.length > 0) {
        var c_start = document.cookie.indexOf(c_name + "=")
        if (c_start > 0)
            var arr = new Array();
        arr = document.cookie.split(";");
        return arr.length
    }
}

export {
    getCookie,
    setCookie,
    delCookie,
    checkCookie
}