
		let Api = {
			"AM_FINDNOTAUTHLIST": '/portal-api/am/findNotAuthList',//轮播图
		"VARIETY_LIST": '/portal-api/n/variety/list',//获取启用的中药材目录品种管理列表
		"VARIETY_GET": '/portal-api/n/variety/get',//查询中药材目录品种管理
		"COMMDITY_SEARCH": "/trade/n/search1",//首页商品搜索 
		'BUYER_HOME_FINDENTPLIST': "/trade/n/buyer/home/findEntpList",//首页商家列表
			
			
		  
			
		}
		module.exports = Api