<template>
  <router-view></router-view>
</template>

<script>
import { getCurrentInstance, } from 'vue'
import { useStore } from "vuex";
export default {
  name: "App",
  components: {},
  setup() {
    const {proxy}=getCurrentInstance();
    const store = useStore();
    let windowWidth = window.innerWidth
    proxy.$store.commit("widthChange", windowWidth);
    // 动态更改favicon
    const changeFavicon = (src) => {
      var link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.href = src+'?timestamp='+Date.now();
    };
    //获取门户信息
    const getPortalInfo = () => {
      proxy
        .$request({
          url: "INDUSTRYMANGE_FINDDEFAULT",
          method: "post",
        })
        .then((res) => {
          if (res.data.code == "000") {
            let result = res.data.result;
            store.commit("setTheme", result.theme);
            store.commit("setModule", result.module);
            store.commit("setColor", result.color);
            store.commit("setFont", result.font);
            store.commit("setPortalInfo",result);
            changeFavicon(proxy.commenUrl + result.browserIcon);
            document.title = result.browserName;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getPortalInfo();
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* min-width: 1400px; */
  font-size: 14px;
}
body {
  margin: 0 !important;
  padding: 0;
  background-color: #f7f8fa;
}
body,
html {
  width: 100%;
  height: 100%;
}
#app {
  width: 100%;
  height: 100%;
}
</style>
