import Axios from 'axios'
import Api from './api.js'
import { getCookie } from './cookie'
export function axiosFileDownload(config){
	let authorization = null;
	if (getCookie("userInfo")) {
		if (JSON.parse(getCookie("userInfo"))) {
			authorization = JSON.parse(getCookie("userInfo")).tokenType + " " + JSON.parse(getCookie("userInfo")).accessToken
		}
	}
	config.method="post";
	if(!config.url){return}
	if(config.url && config.url.substr(0,4).toLowerCase() == "http" ){
		config.url;
	}else{
		config.url = process.env.VUE_APP_BASE_API+process.env.VUE_APP_PUBLIC_NAME+Api[config.url]
	}
	const instance=Axios.create({
		method:config.method,
		headers:config.header ? config.header :{
			'content-type': 'application/json',
			'Authorization': authorization,
			'withCredentials': true
		},
		responseType:"blob"
	})

	return instance(config).then((res)=>{
		let fileName=res.headers["ajax-filename"];
		if(fileName){
			fileName = decodeURI(fileName);
		}
		let mimeType=res.headers["ajax-mimetype"]
		if(fileName && mimeType){
			//创建blob对象
            let blob=new Blob([res.data],{"type":mimeType})
            let blobUrl=URL.createObjectURL(blob);
            let tmpLink=document.createElement("a");
            tmpLink.href=blobUrl;
            tmpLink.download=fileName;
            tmpLink.click();
		}else{
			throw new Error(
                "ajax-filename or ajax-mimeType不存在");
		}
	}).catch((err)=>{
		console.log(err)
	})
}

/*
	页面中具体用法
	proxy.$axiosFileDownload({
		url:"xxx",
		data:{},
	}).then(()=>{
		//隐藏loading代码
	})
*/