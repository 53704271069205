const manageFiles = require.context('./api', true, /\.js$/)//获取api文件夹下所有apiJs
let Api = {}
manageFiles.keys().forEach(key => {
	Api = Object.assign(manageFiles(key), Api)//合并API
})
export default Api




